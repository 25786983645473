/* globals document window */
import { Location } from 'history';

import { DebugModeToggler } from 'shared/utils/debug/DebugModeToggler';

import { triggerInfluxEvent } from './triggerEvent';
import readContext, { decodeTrackingAttribute } from './domUtils';
import { InfluxDataAttribute } from './types';

const MOCKED_DURATION_FOR_TEST = 1234;

const ACTIVE_USER_THRESHOLD_IN_MS = 5000;

const OPEN_EYE_ICON_SVG = `
<svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill="none" d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path fill="none" d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const CLOSED_EYE_ICON_SVG = `
<svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill="none" d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

function injectDebugStyles() {
  const style = document.createElement('style');
  style.textContent = `
.impression-duration-debug-info {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  display: inline-block;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 2px;
}

.impression-duration-debug-dump {
  font-family: monospace;
  display: none;
}

.impression-duration-debug-info:hover .impression-duration-debug-dump {
  display: block;
}
`;

  document.head.appendChild(style);
}

class ImpressionDurationTrackingManager {
  private elementToThenMap: Map<HTMLElement, Date | null>;
  private elementToPausedAtMap: Map<HTMLElement, number>;
  private elementToDurationSoFarMap: Map<HTMLElement, number>;
  private impressionTrackingIntersectionObserver: IntersectionObserver;
  private isTrackingActive: boolean;
  private lastUserInteractionAt: number;
  public debugMode: boolean;

  private constructor(IntersectionObserver: typeof window.IntersectionObserver, debugMode: boolean = false) {
    this.elementToThenMap = new Map<HTMLElement, Date | null>();
    this.elementToPausedAtMap = new Map<HTMLElement, number>();
    this.elementToDurationSoFarMap = new Map<HTMLElement, number>();
    this.debugMode = debugMode;
    this.isTrackingActive = false;
    this.lastUserInteractionAt = 0;

    this.impressionTrackingIntersectionObserver = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        const isVisible = entry.isIntersecting;
        const element = entry.target as HTMLElement;

        if (isVisible) {
          this.elementToThenMap.set(element, new Date());
        } else {
          this.accumulateDuration(element);
          this.elementToThenMap.set(element, null);
        }
      }
    });

    document.addEventListener('beforeNavigate', (event: CustomEvent<{ from: Location; to: Location }>) => {
      if (event.detail.from.pathname === event.detail.to.pathname) return;

      this.triggerEvents();
      this.resumeTracking(true);
      this.cleanUp();
    });

    document.addEventListener('visibilitychange', (event) => {
      if (document.visibilityState === 'hidden') {
        this.pauseTracking();
      } else {
        this.lastUserInteractionAt = event.timeStamp;
        this.resumeTracking();
      }

      this.cleanUp();
    });

    const handleUserInteraction = (event: Event) => {
      this.lastUserInteractionAt = event.timeStamp;
      this.resumeTracking();
    };

    document.addEventListener('click', handleUserInteraction, true);
    document.addEventListener('keydown', handleUserInteraction, true);
    document.addEventListener('mousemove', handleUserInteraction, true);
    document.addEventListener('scroll', handleUserInteraction, true);
    document.addEventListener('touchstart', handleUserInteraction, true);
    document.addEventListener('touchmove', handleUserInteraction, true);

    setInterval(() => {
      // Pause tracking if user has been inactive for too long
      if (performance.now() - this.lastUserInteractionAt > ACTIVE_USER_THRESHOLD_IN_MS) {
        this.pauseTracking();
      }

      for (const element of document.querySelectorAll<HTMLDivElement>('[data-track-impression-duration]')) {
        this.updateDebugInfo(element);
      }
    }, 2000);

    window.addEventListener('load', () => {
      for (const element of document.querySelectorAll<HTMLDivElement>('[data-track-impression-duration]')) {
        this.updateDebugInfo(element);
      }
    });

    window.addEventListener('beforeunload', () => this.triggerEvents(), false);

    this.resumeTracking();
  }

  public static setupFromGlobal(window): ImpressionDurationTrackingManager | undefined {
    if (!window.IntersectionObserver) {
      console.debug(`window.IntersectionObserver is not defined`);
      return;
    }

    const manager = new ImpressionDurationTrackingManager(
      window.IntersectionObserver,
      DebugModeToggler.fromGlobal(window, 'debug_impression_tracking').isEnabled() || false
    );

    if (process.env.IN_TEST !== 'TRUE' && manager.debugMode) {
      injectDebugStyles();
    }

    return manager;
  }

  private getDurationAttribute(element: HTMLElement): number {
    return this.elementToDurationSoFarMap.get(element) || 0;
  }

  private getPausedAtAttribute(element: HTMLElement): number | undefined {
    return this.elementToPausedAtMap.get(element);
  }

  private accumulateDuration(element: HTMLElement) {
    const then = this.elementToThenMap.get(element);

    if (then) {
      const durationSoFarInMs = this.getDurationAttribute(element);
      const pausedAt = this.getPausedAtAttribute(element);
      const diff = pausedAt ? Date.now() - pausedAt : Date.now() - then.getTime();

      this.elementToDurationSoFarMap.set(element, durationSoFarInMs + diff);
    }
  }

  private resumeTracking(forceCleanup: boolean = false) {
    if (!forceCleanup && this.isTrackingActive) return;

    this.isTrackingActive = true;

    const elements = document.querySelectorAll('[data-track-impression-duration]') as NodeListOf<HTMLElement>;

    if (forceCleanup) {
      for (const element of elements) {
        this.elementToPausedAtMap.delete(element);
        this.elementToThenMap.delete(element);
        this.impressionTrackingIntersectionObserver?.unobserve(element);
      }

      for (const element of document.querySelectorAll<HTMLDivElement>('.impression-duration-debug-info')) {
        element.remove();
      }
    }

    if (elements.length === 0) return;

    for (const element of elements) {
      this.elementToPausedAtMap.delete(element);
      this.elementToThenMap.set(element, null);
      this.impressionTrackingIntersectionObserver?.observe(element);
    }
  }

  private pauseTracking() {
    if (!this.isTrackingActive) return;

    this.isTrackingActive = false;

    for (const element of this.elementToThenMap.keys()) {
      this.accumulateDuration(element);
      this.elementToThenMap.delete(element);
      this.elementToPausedAtMap.set(element, new Date().getTime());

      if (this.impressionTrackingIntersectionObserver) {
        this.impressionTrackingIntersectionObserver.unobserve(element);
      }
    }
  }

  private triggerEvents() {
    for (const element of this.elementToThenMap.keys()) {
      if (!document.contains(element)) continue;

      const trackingAttributeId = element.getAttribute('data-track-impression-duration');

      if (trackingAttributeId) {
        const params = decodeTrackingAttribute<InfluxDataAttribute>(trackingAttributeId);

        if (!params) return;

        const context = readContext(element);

        const durationSoFarInMs = this.getDurationAttribute(element);
        const then = this.elementToThenMap.get(element);
        const sinceThen = then ? Date.now() - then.getTime() : 0;
        const totalDurationInMs =
          process.env.IN_TEST === 'TRUE' ? MOCKED_DURATION_FOR_TEST : durationSoFarInMs + sinceThen;

        triggerInfluxEvent(
          {
            measurement: params.measurement,
            // Merge with inherited attributes
            attributes: {
              ...context.influxContextAttributes,
              ...params.attributes,
              value: totalDurationInMs,
            },
          },
          {
            // Flush right way to prevent losing data points while leaving the page
            forceFlush: true,
          }
        );

        this.elementToThenMap.delete(element);
      }
    }
  }

  private cleanUp() {
    // Remove elements that are no longer in the DOM
    for (const element of this.elementToDurationSoFarMap.keys()) {
      if (!document.body.contains(element)) {
        this.elementToDurationSoFarMap.delete(element);
      }
    }

    for (const element of this.elementToPausedAtMap.keys()) {
      if (!document.body.contains(element)) {
        this.elementToPausedAtMap.delete(element);
      }
    }
  }

  private updateDebugInfo(element: HTMLElement) {
    if (!this.debugMode) return;

    let debugId = element.getAttribute('data-track-impression-duration-debug-id');

    if (!debugId) {
      debugId = Math.random().toString(36).substring(2, 15);
      element.setAttribute('data-track-impression-duration-debug-id', debugId);
    }

    const debugInfoContainer = document.getElementById(debugId);
    const html = this.renderDebugInfo(element, debugId);

    if (debugInfoContainer) {
      // Update
      debugInfoContainer.outerHTML = html;
    } else {
      // Create
      const template = document.createElement('template');
      template.innerHTML = html;
      document.body.appendChild(template.content?.firstElementChild as unknown as HTMLDivElement);
    }
  }

  private renderDebugInfo(element: HTMLElement, debugId: string) {
    const context = readContext(element);
    const params = decodeTrackingAttribute<InfluxDataAttribute>(element.getAttribute('data-track-impression-duration'));

    if (!params) {
      return `
<div id="${debugId}" class="impression-duration-debug-info">
  (no params)
</div>
`;
    }

    const durationSoFarInMs = this.getDurationAttribute(element);
    const then = this.elementToThenMap.get(element);
    const sinceThen = then ? Date.now() - then.getTime() : 0;
    const totalDurationInMs = durationSoFarInMs + sinceThen;
    const iconSvg = this.isTrackingActive ? OPEN_EYE_ICON_SVG : CLOSED_EYE_ICON_SVG;
    const jsonDump = JSON.stringify(
      { measurement: params.measurement, attributes: { ...context.influxContextAttributes, ...params.attributes } },
      null,
      2
    );

    return `
<div id="${debugId}" class="impression-duration-debug-info">
  ${params.measurement} ${iconSvg} ${totalDurationInMs}ms
  <pre class="impression-duration-debug-dump">${jsonDump}</pre>
</div>
`;
  }
}

export default function setupImpressionDurationTracking() {
  ImpressionDurationTrackingManager.setupFromGlobal(window);
}
