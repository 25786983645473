/* globals CustomEvent */

declare global {
  interface Window {
    prdebug: {
      // Global methods
      on: (persist?: boolean) => any;
      off: (persist?: boolean) => any;
    } & {
      [debugParam: string]: DebugModeToggler;
    };
  }
}

export type EventsMap = {
  debugModeTogglerValueWasSet: { debugParam: string; value: boolean };
};

export class DebugModeToggler {
  private window: Window;

  private debugParam: string;

  private value: boolean;

  private constructor(window: Window, debugParam: string, defaultValue: boolean = false) {
    this.window = window;
    this.debugParam = debugParam;

    this.value = defaultValue;

    if (!this.overrideValueFromQueryParameter()) {
      this.overrideWithValueFromStorage();
    }

    if (this.value) console.debug(`Debug mode enabled for ${debugParam}`, this);
  }

  public static fromGlobal(window: Window, debugParam: string): DebugModeToggler {
    window.prdebug = window.prdebug || {
      on: (persist) => {
        for (const key in window.prdebug ?? {}) {
          if (key === 'on' || key === 'off') continue;
          window.prdebug?.[key].set(true, persist);
        }
      },
      off: (persist) => {
        for (const key in window.prdebug ?? {}) {
          if (key === 'on' || key === 'off') continue;
          window.prdebug?.[key].set(false, persist);
        }
      },
    };

    window.prdebug[debugParam] = window.prdebug[debugParam] || new DebugModeToggler(window, debugParam);

    return window.prdebug[debugParam];
  }

  private overrideValueFromQueryParameter(): boolean {
    switch (new URLSearchParams(this.window.location.search).get(this.debugParam)) {
      case 'true':
      case '1':
        this.set(true);
        return true;
      case 'false':
      case '0':
        this.set(false);
        return true;
      default:
        return false;
    }
  }

  private overrideWithValueFromStorage(): boolean {
    const storedRawValue = this.window.document.cookie
      .split('; ')
      .map((part) => part.split('='))
      .find(([key]) => key === this.debugParam)?.[1];

    switch (storedRawValue) {
      case 'true':
        this.set(true);
        return true;
      case 'false':
        this.set(false);
        return true;
      default:
        return false;
    }
  }

  public toggle(persist: boolean = true): void {
    this.set(!this.value, persist);
  }

  public set(value: boolean, persist: boolean = true) {
    this.value = value;
    this.window.document.dispatchEvent(
      new CustomEvent('debugModeTogglerValueWasSet', { detail: { debugParam: this.debugParam, value } })
    );

    if (persist) this.persist(value);
  }

  public isEnabled(): boolean {
    return this.value;
  }

  private persist(value: boolean) {
    if (value) {
      // Expires in 60 minutes
      this.window.document.cookie = `${this.debugParam}=${value}; path=/; domain=.${process.env.PR__DOMAIN}; secure; max-age=3600`;
    } else {
      // Delete cookie
      this.window.document.cookie = `${this.debugParam}=; path=/; domain=.${process.env.PR__DOMAIN}; secure; max-age=0`;
    }
  }

  // React hook
  public watch(React: typeof import('react')) {
    const [value, setValue] = React.useState(this.value);

    React.useEffect(() => {
      const handleSet = (event: CustomEvent<EventsMap['debugModeTogglerValueWasSet']>) => {
        if (event.detail.debugParam === this.debugParam) {
          setValue(event.detail.value);
        }
      };

      this.window.document.addEventListener('debugModeTogglerValueWasSet', handleSet);

      return () => {
        this.window.document.removeEventListener('debugModeTogglerValueWasSet', handleSet);
      };
    });

    return value;
  }
}
